const Arrow = () => {
  return (
    <svg
      class="arrow"
      width="16"
      height="16"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.1573 18L15.1348 5.02247V17.5955L18 14.6966V0H3.33708L0.438203 2.89888H12.9775L0 15.8764L2.1573 18Z"
        fill="currentcolor"
      ></path>
    </svg>
  );
};

export default Arrow
