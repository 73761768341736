import { mergeProps, type Component } from 'solid-js';
import FormFieldWrapper from './FormFieldWrapper';
import FormInputLine from './FormInputLine';
import Label from './Label';

interface TextFieldProps {
  label: string;
  name: string;
  type: string;
  submitting: boolean;
  required?: boolean;
  onInput: (e: Event) => void;
}

const TextField: Component<TextFieldProps> = (props) => {
  const finalProps = mergeProps(
    {
      type: 'text',
      required: true,
      submitting: false
    },
    props
  );

  return (
    <FormFieldWrapper>
      <Label name={finalProps.name}>{finalProps.label}</Label>
      <input
        id={finalProps.name}
        class="form__field"
        type={finalProps.type}
        name={finalProps.name}
        required={finalProps.required}
        onInput={props.onInput}
        disabled={finalProps.submitting}
      />
      <FormInputLine />
    </FormFieldWrapper>
  );
};

export default TextField;
