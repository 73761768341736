import { children, type Component, type JSXElement } from 'solid-js'


interface FormFieldWrapperProps {
  children: JSXElement
}

const FormFieldWrapper: Component<FormFieldWrapperProps> = (props) => {
  const safeChildren = children(() => props.children)
  return (
    <div class="form__input__wrapper">
      {safeChildren()}
    </div>
  );
};


export default FormFieldWrapper