import type { Component, JSXElement } from "solid-js";

interface LabelProps {
    name: string;
    children: JSXElement;
    id?: string;
}

const Label: Component<LabelProps> = (props) => {
  return (
    <label id={props.id} class="mini-sub" for={props.name}>
      {props.children}
    </label>
  );
};

export default Label;
