import { mergeProps, type Component, type JSXElement } from 'solid-js';
import Arrow from '../../icons/Arrow';

interface FormButtonProps {
  icon?: boolean;
  ariaLabel?: string;
  type: 'submit' | 'button' | 'reset' | undefined;
  children: JSXElement;
  submitting: boolean;
}

const FormButton: Component<FormButtonProps> = (props) => {
  const finalProps = mergeProps(
    {
      icon: true,
      submitting: false,
      type: 'submit',
    },
    props
  );

  {
    /* TODO: typescript error type */
  }
  return (
    <button
      type={finalProps.type}
      disabled={finalProps.submitting}
    >
      <div>
        {finalProps.icon && (
          <span class="icon icon__pre">
            <Arrow />
          </span>
        )}
        <span class="label">
          {!finalProps.submitting ? finalProps.children : <>Sending...</>}
        </span>
        {finalProps.icon && (
          <span class="icon icon__post">
            <Arrow />
          </span>
        )}
      </div>
    </button>
  );
};

export default FormButton;
