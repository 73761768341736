import { mergeProps, type Component } from 'solid-js';
import FormFieldWrapper from './FormFieldWrapper';
import Label from './Label';

interface Option {
  label: string;
}

interface RadioFieldProps {
  name: string;
  label: string;
  options: Option[];
  submitting: boolean;
  onInput: (e: Event) => void;
}

const RadioField: Component<RadioFieldProps> = (props) => {
  const finalProps = mergeProps(
    {
      submitting: false,
    },
    props
  );

  const labelToId = (label: string) => {
    const newLabel = label.replaceAll(' ', '-').toLowerCase();

    return newLabel;
  };

  return (
    <FormFieldWrapper>
      <div id="label__subject" class="mini-sub">
      {finalProps.label}
        </div>
      <div role="radiogroup" aria-labelledby="label__subject" class={`form__selection ${finalProps.submitting ? 'is-submitting' : ''}`}>

        {props.options.map((option: Option, index: number) => {
          const optionId = labelToId(option.label);

          return (
            <div class={`form__input-radio`}>
              <input
                class="form__input-radioOption"
                value={labelToId(option.label)}
                type="radio"
                id={optionId}
                name={finalProps.name}
                disabled={finalProps.submitting}
                onInput={finalProps.onInput}
              />
              <label
                class={`mini-sub ${
                  index === finalProps.options.length - 1 ? 'border-r' : ''
                }`}
                for={optionId}
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
      <div class="form__input__line"></div>
    </FormFieldWrapper>
  );
};

export default RadioField;
