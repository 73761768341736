import { mergeProps, type Component } from 'solid-js';
import FormFieldWrapper from './FormFieldWrapper';
import FormInputLine from './FormInputLine';
import Label from './Label';

interface TextAreaProps {
  name: string;
  label: string;
  submitting: boolean;
  onInput: (e: Event) => void;
}

const TextArea: Component<TextAreaProps> = (props) => {
  const finalProps = mergeProps(
    {
      submitting: false
    },
    props
  );

  return (
    <FormFieldWrapper>
      <Label  id="label__thoughts" name={finalProps.name}>{finalProps.label}</Label>
      <textarea
        required
        id={finalProps.name}
        class="form__field"
        name={finalProps.name}
        onInput={finalProps.onInput}
        disabled={finalProps.submitting}
        maxLength={500}
        rows={5}
      ></textarea>

      <FormInputLine />
    </FormFieldWrapper>
  );
};

export default TextArea;
