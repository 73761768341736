import {
  createEffect,
  createSignal,
  onCleanup,
  type Component,
} from 'solid-js';

interface RecaptchaProps {
  siteKey: string;
  onVerify: (token: string) => void;
}

const Recaptcha: Component<RecaptchaProps> = (props) => {
  const [recaptchaToken, setRecaptchaToken] = createSignal(null);
  const SITEKEY = props.siteKey;

  const handleLoaded = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITEKEY, { action: 'homepage' })
        .then((token) => {
          setRecaptchaToken(token);

          if (props.onVerify) {
            props.onVerify(token);
          }
        });
    });
  };

  createEffect(() => {
    // Initialize Recaptcha
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${SITEKEY}`;
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);

    const style = document.createElement('style');
    style.innerHTML = `
    .grecaptcha-badge {
        visibility: hidden !important;
        opacity: 0 !important;
        position: absolute !important;
        left: 0 !important;
        z-index: -1 !important;
    }`;

    document.head.appendChild(style);

    onCleanup(() => {
      script.removeEventListener('load', handleLoaded);
      document.body.removeChild(script);
      document.head.removeChild(style);
    });
  }, []);

  return (
    <div class="recaptcha__wrapper">
      <label for="g-recaptcha-response" class="visually__hidden">reCAPTCHA challenge</label>
      <div class="g-recaptcha" data-sitekey={SITEKEY} data-size="invisible" />
      <p class="recaptcha__notice">
        This site is protected by reCAPTCHA and the Google 
        <a href="https://policies.google.com/privacy" target="_blank">
          Privacy Policy
        </a>{' '}
        and 
        <a href="https://policies.google.com/terms" target="_blank">
          Terms of Service
        </a>{' '}
        apply.
      </p>
    </div>
  );
};

export default Recaptcha;
